import React, { useEffect } from 'react';
import { useState } from "react"
import { HashLink } from 'react-router-hash-link';


function Header() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    useEffect(() => {
        let url = window.location.href.split("/");
        let target = url[url.length - 1].toLowerCase();
        let element = document.getElementById(target);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, []);
    
    return (
      <nav>
        <div className='container'>
            <div className='headerScope'>
                <a href='/' className='navbar-brand'> 
                    <img src='/images/Logo.png' alt='' width={166}></img>
                </a>
                <ul>
                    <li className='nav-item'>
                        <HashLink smooth to='/#About' className='nav-item-link'> ABOUT US </HashLink>
                    </li>
                    <li className='nav-item'>
                        <HashLink smooth to='/#Games' className='nav-item-link'> GAMES </HashLink>
                    </li>
                    <li className='nav-item'>
                        <HashLink smooth to='/#Games' className='nav-item-link'> APP </HashLink>
                    </li>
                    <li className='nav-item'>
                        <HashLink to='/careers' className='nav-item-link'> CAREERS </HashLink>
                    </li>
                </ul>
                <div class="mobileHamburgerMenu" className={isNavExpanded ? "mobileHamburgerMenu active" : "mobileHamburgerMenu"} onClick={() => {setIsNavExpanded(!isNavExpanded); }}>
                    <div class="navLineScope">
                        <div class="navLine1"></div>
                        <div class="navLine2"></div>
                        <div class="navLine3"></div>
                    </div>
                </div>
                <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                    <ul>
                        <li className='nav-item'>
                            <HashLink smooth to='/#About' onClick={() => {setIsNavExpanded(!isNavExpanded); }} className='nav-item-link'> ABOUT US </HashLink>
                        </li>
                        <li className='nav-item'>
                            <HashLink smooth to='/#Games' onClick={() => {setIsNavExpanded(!isNavExpanded); }} className='nav-item-link'> GAMES </HashLink>
                        </li>
                        <li className='nav-item'>
                            <HashLink smooth to='/#Games' onClick={() => {setIsNavExpanded(!isNavExpanded); }} className='nav-item-link'> APP </HashLink>
                        </li>
                        <li className='nav-item'>
                            <HashLink to='/careers' onClick={() => {setIsNavExpanded(!isNavExpanded); }} className='nav-item-link'> CAREERS </HashLink>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
        <div className={isNavExpanded ? "menuOverlay active" : "menuOverlay"} onClick={() => {setIsNavExpanded(!isNavExpanded); }}></div>
      </nav>
    )
}

export default Header;
