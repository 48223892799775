import React from 'react'
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <div className='footerScope'>
        <div className='container'>
            <div className='footerTopBlock'>
                <div className='footerLeftBlock'>
                    <a href='/'> 
                        <img src='/images/LogoFooter.png' alt='' width={146}></img>
                    </a>
                </div>
                <div className='footerMiddleBlock'>
                    <ul>
                        <li>
                            <HashLink smooth to='/#About' className='nav-item-link'> About Us </HashLink>
                        </li>
                        <li>
                            <HashLink smooth to='/#Games' className='nav-item-link'> Games </HashLink>
                        </li>
                        <li>
                            <HashLink smooth to='/#Games' className='nav-item-link'> App </HashLink>
                        </li>
                        <li>
                            <HashLink to='/careers' className='nav-item-link'> Careers </HashLink>
                        </li>
                    </ul>
                </div>
                <div className='footerRightBlock'>
                    <a href='https://www.facebook.com/The-Splash-Labs-102384282724730/' target="_blank" rel="noopener noreferrer">
                        <img src='/images/Facebook-Icon.png' alt='Facebook' width={34}></img>
                    </a>
                    <a href='https://www.instagram.com/thesplashlabs/' target="_blank" rel="noopener noreferrer"> 
                        <img src='/images/Instagram-Icon.png' alt='Instagram' width={34}></img>
                    </a>
                    <a href='https://www.linkedin.com/company/the-splash-labs/' target="_blank" rel="noopener noreferrer"> 
                        <img src='/images/Linkedin-Icon.png' alt='Linkedin' width={34}></img>
                    </a>
                </div>
            </div>
            <div className='footerMidBlock'>
                <a href='#!' onClick={(e) => {window.location.href ='mailto:hr@thesplashlabs.com';}}> 
                    info@thesplashlabs.com
                </a>
            </div>
            <div className='footerBottomBlock'>
                    Copyright © 2022 - Splash Company
            </div>
        </div>
    </div>
  )
}

export default Footer