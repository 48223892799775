import React from 'react'

function OpenPositions() {
  return (
    <div className='openPositionsScope'>
        <div className='container'>
            <div className='openPositionsBlock'>
                <h2>Open Positions</h2>
                <div className='openPositionItemScope'>
                    <div className='openPositionItem'>
                        <h3>Senior Game Developer</h3>
                        <a href="/careers/seniorgamedeveloper" className='buttonPrimary'>Apply Now</a>
                    </div>
                    <div className='openPositionItem'>
                        <h3>Marketing Manager</h3>
                        <a href="/careers/marketingmanager" className='buttonPrimary'>Apply Now</a>
                    </div>
                    <div className='openPositionItem'>
                        <h3>Senior Backend Engineer</h3>
                        <a href="/careers/seniorbackendengineer" className='buttonPrimary'>Apply Now</a>
                    </div>
                    <div className='openPositionItem'>
                        <h3>Product Specialist</h3>
                        <a href="/careers/productspecialist" className='buttonPrimary'>Apply Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OpenPositions