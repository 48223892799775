import '../../../src/styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from '../Banner';
import TextCard from '../TextCard';
import OptionsCard from '../OptionsCard';
import CareersCard from '../CareersCard';


function Home(){
    return(
        <>
        <Banner />
        <TextCard />
        <OptionsCard />
        <CareersCard />
        </>
    )
}

export default Home;