import React from 'react'

function CareersCard() {
  return (
    <div className="careersCardScope">
        <div className='container'>
            <div className='careersCardBlock'>
                <div className='careersCardLeftBlock'>
                    <img src='/images/CareersImage.png' alt=''></img>
                </div>
                <div className='careersCardRightBlock'>
                    <h2>Careers</h2>
                    <p>Together we will create high quality mobile games and mobile applications that will be played for years. How about joining us?</p>
                    <a href='/careers' className='buttonPrimary'>See All Position</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CareersCard