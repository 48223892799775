import "../../../src/styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";

function PrivacyPolicy() {
  return (
    <div className="container" style={{ textAlign: "justify" }}>
      <h4>Privacy Policy</h4>
      <br />
      <div>
        <h3>Our Commitment to You</h3>
        <p>
          Splash Games is dedicated to providing internet users with the highest
          level of transparency and control over the use of their data. In order
          for us to provide you with our services we are required to collect and
          process certain personal information about you and your activity.
          <br />
          By entrusting us with your information, we would like to assure you of
          our commitment to keep such information private. We have taken
          measurable steps to protect the confidentiality, security and
          integrity of this Information. We encourage you to review the
          following information carefully.
        </p>
      </div>
      <br />
      <div>
        <h3>How do we receive data about you?</h3>
        <p>
          <h5>We receive your Personal Data from various sources:</h5>
          Registration information - When you voluntarily provide us your
          personal details in order to create a Splash Games.ai account or
          register to our Service (for example, your name and email address);
          When you communicate with us - For example when you send us an email,
          we collect the Personal Information you provided us with. Technical
          information - When operating our services, we use various
          technological tracking tools to collect information regarding your use
          of the Website. Along with our partners, we may use tracking
          technologies such as cookies, JavaScript, beacons and Local Storage
          such as HTML5, which are used to store content information and
          preferences. You can learn more about the tracking tools we use by
          visiting our <a href="/cookie-policy">Cookie Policy</a>. Third party
          information – We may receive your data from third party providers who
          help us with the provision and maintenance of our Services, such as
          traffic analytics vendors, fraud prevention agencies and others.
        </p>
      </div>
      <br />
      <div>
        <h3>What type of data we collect?</h3>
        <p>
          <h5>Personal Data</h5>
          In the course of using the Service, we may ask you to provide us with
          certain Personal Data to provide and improve the Service, to contact
          or identify you, to enable you to access certain parts of the Website,
          and as otherwise indicated in this Policy. We collect the following
          Personal Data about you:
          <br />
          <br />
          <b>Registration information</b> - your name, your mailing address,
          your email address, telephone number, and similar contact details.
          <br />
          <b>When using our Website</b> - our webserver will collect your
          IP-address, and information about your general location (such as city
          and country).
          <br />
          <br />
          <h5>Non-Personal Data</h5>
          We also collect data about the use of our Service and the
          characteristics and activities of users, in order to operate it and
          improve it. We may collect the following non-Personal Data:
          <br />
          <br />
          <b>Technical information</b> - this category includes data such as
          website visits, the browser you are using and its display settings,
          your operating system, device type, session start/stop time, time
          zone, network connection type (e.g., Wi-Fi, cellular), and cookie
          information.
          <br />
          <b>Information from third parties</b> - this category includes
          information we receive from our business partners. This may include
          pseudonymous advertiser identifiers that some Advertisers or other
          third party ad platforms choose to share with us. This information is
          also used to enhance data points about a particular unique browser or
          device.
          <br />
          If we combine Personal Data with non-Personal Data, the combined data
          will be treated as Personal Data. Further Personal Data will only be
          stored and processed if you voluntarily provide it to us, e.g. through
          a contact form.
        </p>
      </div>
      <br />
      <div>
        <h3>Tracking Technologies</h3>
        <p>
          When you visit or access our Services we use (and authorize 3rd
          parties to use) pixels, cookies, events and other technologies. Those
          allow us to automatically collect information about you, your device
          and your online behavior, in order to enhance your navigation in our
          Services, improve our Website’s performance, perform analytics and
          customize your experience on it. In addition, we may merge information
          we have with information collected through these tracking technologies
          with information we may obtain from other sources and, as a result,
          such information may become Personal Data.
          <br />
          To learn more about our Tracking Technologies please visit our{" "}
          <a href="/cookie-policy">Cookie Policy</a> page.
        </p>
      </div>
      <br />
      <div>
        <h3>How do we use the data we collect?</h3>
        <p>
          <b>Provision of service</b> – for the provision and improvement of our
          Services, including for support and to respond to your queries.
          <br />
          <b>Service announcements</b> – we will use your Personal Data to
          communicate with you and to keep you informed of our latest updates to
          our Services and offer you service offers.
          <br />
          <b>Marketing purposes</b> – we may use your Personal Data (such as
          your email address or phone number). For example, by subscribing to
          our newsletter you will receive tips and announcements straight to
          your email account. We may also send you promotional material
          concerning our services or our partners' services (which we believe
          may interest you), including but not limited to, by building an
          automated profile based on your Personal Data, for marketing purposes.
          You may choose not to receive our promotional or marketing emails (all
          or any part thereof) by clicking on the “unsubscribe” link in the
          emails that you receive from us. Please note that even if you
          unsubscribe from our newsletter, we may continue to send you
          service-related updates and notifications, or reply to your queries
          and feedback you provide us.
          <br />
          <b>Opt-out of receiving marketing materials</b> - If you do not want
          us to use or share your Personal Data for marketing purposes, you may
          opt-out in accordance with this "Opt-out" section. Please note that
          even if you opt-out, we may still use and share your Personal Data
          with third parties for non-marketing purposes (for example to fulfill
          your requests, communicate with you and respond to your inquiries,
          etc.). In such cases, the companies with whom we share your Personal
          Data are authorized to use your Personal Data only as necessary to
          provide these non-marketing services.
          <br />
          <b>Analytics, surveys and research</b> – from time to time, we may
          conduct surveys or test features, and analyze the data we have to
          develop, evaluate and improve these features, all in order to improve
          our Service and think of new and exciting features for our users.
          <br />
          <b>Protecting our interests</b> – we may use your Personal Data when
          we believe it’s necessary in order to take precautions against
          liabilities, investigate and defend ourselves against any third party
          claims or allegations, investigate and protect ourselves from fraud,
          protect the security or integrity of our services and protect the
          rights and property of Splash Games, its users and/or partners.
          <br />
          <b>Enforcing of policies</b> – we may use your Personal Data in order
          to enforce our policies, including but not limited to our Terms.
          <br />
          <b>Compliance with legal and regulatory requirements</b> – we may use
          your Personal Data to investigate violations, and as required by law,
          regulation or other governmental authority, or to comply with a
          subpoena or similar legal process.
        </p>
      </div>
      <br />
      <div>
        <h3>With whom do we share your Personal Data?</h3>
        <p>
          <b>Internal concerned parties</b> – we share your information with
          companies in our group, as well as our employees, in order to provide
          you with our services.
          <br />
          <b>Business partners</b> – we share your information with business
          partners, such as storage and analytics providers who help us provide
          you with our service. These third parties may have access to your
          Personal Data so that they may perform these tasks on our behalf, but
          they are obligated to comply with this Privacy Policy and may not use
          your Personal Data for any other purpose.
          <br />
          <b>Compliance with laws and law enforcement entities</b> – we
          cooperate with government and law enforcement officials and private
          parties to enforce and comply with the law. We will disclose any data
          about you to government or law enforcement officials or private
          parties as we, in our sole discretion, believe necessary or
          appropriate to respond to claims and legal process (including but not
          limited to subpoenas), to protect our or a third party's property and
          rights, to protect the safety of the public or any person, or to
          prevent or stop any activity we may consider to be, or to pose a risk
          of being, illegal, unethical, inappropriate or legally actionable. We
          also may be required to disclose an individual’s Personal Data in
          response to a lawful request by public authorities, including to meet
          national security or law enforcement requirements.
          <br />
          <b>Merger and acquisitions</b> – from time to time, we may conduct
          surveys or test features, and analyze the data we have to develop,
          evaluate and improve these features, all in order to improve our
          Service and think of new and exciting features for our users.
          <br />
          <b>Protecting our interests</b> – we may use your Personal Data when
          we believe it’s necessary in order to take precautions against
          liabilities, investigate and defend ourselves against any third party
          claims or allegations, investigate and protect ourselves from fraud,
          protect the security or integrity of our services and protect the
          rights and property of Splash Games, its users and/or partners.
          <br />
          <b>Enforcing of policies</b> – we may share your data if we enter into
          a business transaction such as a merger, acquisition, reorganization,
          bankruptcy, or sale of some or all of our assets. Any party that
          acquires our assets as part of such a transaction may continue to use
          your data in accordance with the terms of this Privacy Policy.
        </p>
      </div>
      <br />
      <div>
        <h3>Transfer of data outside the EEA</h3>
        <p>
          Please note that some data recipients may be located outside the EEA.
          In such cases we will transfer your data only to such countries as
          approved by the European Commission as providing adequate level of
          data protection, or enter into legal agreements ensuring an adequate
          level of data protection.
        </p>
      </div>
      <br />
      <div>
        <h3>How we protect your information</h3>
        <p>
          We have implemented administrative, technical, and physical safeguards
          to help prevent unauthorized access, use, or disclosure of your
          Personal Data. Your data is stored on secure servers and isn’t
          publicly available. We limit access of your information only to those
          employees or partners on a “need to know” basis, in order to enable
          the carrying out of the agreement between us.
          <br />
          While we seek to protect your information to ensure that it is kept
          confidential, we cannot absolutely guarantee its security. You need to
          help us prevent unauthorized access to your account by protecting your
          password appropriately and limiting access to your account. You will
          be solely responsible for keeping your password confidential and for
          all use of your password and your account, including any unauthorized
          use. You should also be aware that there is always some risk involved
          in transmitting information over the internet. While we strive to
          protect your Personal Data, we cannot ensure or warrant the security
          and privacy of your Personal Data or other content you transmit using
          the service, and you do so at your own risk.
        </p>
      </div>
      <br />
    </div>
  );
}

export default PrivacyPolicy;
