import React, { useEffect } from 'react';
import Typewriter from "typewriter-effect";

function Banner() {
    useEffect(() => {
        let url = window.location.href.split("/");
        let target = url[url.length - 1].toLowerCase();
        let element = document.getElementById(target);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, []);
  return (
    <div className='bannerScope'>
        <div className='container-fluid p-0'>
            <div className='bannerBlock' style={{ backgroundImage: 'url(/images/Banner.png)' }}>
                <div className='bannerImgEffect'>
                    <img src='/images/SpinImg-1.png' width={23} className='img-1' alt=''></img>
                    <img src='/images/SpinImg-2.png' width={44} className='img-2' alt=''></img>
                    <img src='/images/SpinImg-3.png' width={44} className='img-3' alt=''></img>
                    <img src='/images/SpinImg-4.png' width={36} className='img-4' alt=''></img>
                    <img src='/images/SpinImg-5.png' width={36} className='img-5' alt=''></img>
                </div>
                <h1>To have more fun <br/>
                <Typewriter 
                    options={{
                        strings: ['Game', 'App'],
                        autoStart: true,
                        loop: true,
                        pauseFor: 1000
                    }}
                /> 
                we produce</h1>
                <img onClick={() => {
                        let ScrollTo = document.getElementById("About");
                        ScrollTo && ScrollTo.scrollIntoView({ behavior: "smooth", block: "start" });
                        }} 
                className='scrollDownIcon' src='/images/ScrollDown.png' alt=''></img>
            </div>
        </div>
    </div>
  )
}

export default Banner