import React from 'react'

function OptionsCard() {
    const optionsItem = [
        { 
            itemId: 1, 
            itemTitle: "Hidden Master", 
            itemDescription: "Look for differences and see them in beautiful pictures! There are countless various pictures in this game. While playing this game, you can travel to different places, live new lives and enjoy the fun.",
            itemImage: "/images/Splash-HiddenMaster.png",
            itemAppStoreLink: "#",
            itemPlayStoreLink: "https://play.google.com/store/apps/details?id=com.gitberry.hiddenmaster",
            itemAppStoreButtonStatus : false,
            itemPlayStoreButtonStatus : true,
        },
        { 
            itemId: 2, 
            itemTitle: "Splash Music", 
            itemDescription: "Start listening to music online! Splash Music provides users with an attractive music experience. It's completely free and we guarantee it will stay free forever!",
            itemImage: "/images/Splash-Music.png",
            itemAppStoreLink: "#",
            itemPlayStoreLink: "https://play.google.com/store/apps/details?id=com.splash.app.music",
            itemAppStoreButtonStatus : false,
            itemPlayStoreButtonStatus : true,

        },
        { 
            itemId: 3, 
            itemTitle: "PixLab: Photo Editor & Art", 
            itemDescription: "Edit your beautiful photos with Splash Editor! Splash Editor is completely free. Splash Editor is your all-in-one editor with all the tools you need to personalize and make your content stand out. Make your photos awesome!",
            itemImage: "/images/Splash-Editor.png",
            itemAppStoreLink: "https://apps.apple.com/tr/app/splash-editor/id6444607519",
            itemPlayStoreLink: "#",
            itemAppStoreButtonStatus : true,
            itemPlayStoreButtonStatus : false,
        },
    ];
  return (
    <div id='Games' className='optionsCardMain'>
        {optionsItem.map((item) => (
        <div key={item.itemId} className='optionsCardScope'>
            <div className='container'>
                <div className='optionsCardBlock'>
                    <div className='optionsCardLeftBlock'>
                        <h2>{item.itemTitle}</h2>
                        <p>{item.itemDescription}</p>
                        <div className='optionsCardDownloadLink'>
                            {item.itemAppStoreButtonStatus === true ? (
                                <a href={item.itemAppStoreLink} className='buttonSecondary' target="_blank" rel="noopener noreferrer"><img src="/images/AppStoreIcon.png" width={24} alt=''></img> App Store</a>
                            ) : ( 
                                null
                            )}
                            {item.itemPlayStoreButtonStatus === true ? (
                                <a href={item.itemPlayStoreLink} className='buttonSecondary' target="_blank" rel="noopener noreferrer"><img src="/images/PlayStoreIcon.png" width={24} alt=''></img> Google Play</a>
                            ) : ( 
                                null
                            )}
                        </div>
                    </div>
                    <div className='optionsCardRightBlock'>
                        <img src={item.itemImage} alt='Options Images'></img>
                    </div>
                </div>
            </div>
        </div>
        ))}
    </div>
    
  )
}

export default OptionsCard