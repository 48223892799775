import React from 'react'

function SeniorBackendEngineer() {

  return (
    <div className='positionDetailScope'>
    <div className='container'>
        <div className='positionDetailBlock'>
            <div className='positionDetailBackBlock'>
                <a href="/careers"> 
                <span className='backIcon'>
                    <img src='/images/ArrowLeft.png' width={7} alt=''></img>
                </span> 
                <span>Back</span> </a>
            </div>
            <div className='positionDetailContentBlock'>
                <h1>Senior Backend Engineer</h1>
                <h3>Jod Description</h3>
                <p>We are looking for a Senior Backend Engineer to join our team. As a Senior Backend Engineer, you will be responsible for designing, developing and deploying the large-scale backend architecture of mobile games. In this role. you'll work with TechOps to develop highly scalable technologies that allow millions of users to play our games. You'll also work closely with the development team to do what's best for the games and app.</p>
                <p>At The Splash Labs, all team members have a real chance to contribute to the final product and make an impact on the company. If you want to be a core member of a rapidly growing company, you may be the one we are looking for!</p>
                <h3>Responsibilities</h3>
                <ul>
                    <li>Designing, testing and implementing features in our core services</li>
                    <li>Development of large-scale backend architecture of mobile games</li>
                    <li>Creating valuable tools to support the game development process</li>
                    <li>Provide well-structured code and participate in code review sessions</li>
                    <li>Flexible code generation that can be easily updated as the product evolves</li>
                </ul>
                <h3>Requirements</h3>
                <ul>
                    <li>Strong knowledge of 00P and familiarity with Design Patterns</li>
                    <li>At least 5 years of backend development experience</li>
                    <li>3+ years of experience in Java Spring Boot based backend systems</li>
                    <li>Familiar with Java, Spring, Redis, MySQL and AWS Cloud Systems</li>
                    <li>Passion for games and game programming</li>
                    <li>Appreciating and understanding teamwork</li>
                </ul>
                <img className='d-block mt-5' src="/images/Logo.png" alt="" width="106" />
                <a href='#!' onClick={(e) => {window.location.href ='mailto:hr@thesplashlabs.com';}} className='buttonPrimary mt-5'>Apply Now</a>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default SeniorBackendEngineer