import React from 'react'

function MarketingManager() {

  return (
    <div className='positionDetailScope'>
    <div className='container'>
        <div className='positionDetailBlock'>
            <div className='positionDetailBackBlock'>
                <a href="/careers"> 
                <span className='backIcon'>
                    <img src='/images/ArrowLeft.png' width={7} alt=''></img>
                </span> 
                <span>Back</span> </a>
            </div>
            <div className='positionDetailContentBlock'>
                <h1>Marketing Manager</h1>
                <h3>Jod Description</h3>
                <p>We are looking for an experienced Marketing Manager to run global performance marketing campaigns for our mobile games. As a Marketing Manager, you will be responsible for defining, designing and executing marketing campaigns that raise awareness for our games. You will also work closely with the design, and techninal teams to ensure the best implementation and development of digital marketing strategies. </p>
                <p>At The Splash Labs, all team members have a real chance to contribute to the final product and make an impact on the company. If you want to be a core member of a rapidly growing company, you may be the one we are looking for!</p>
                <h3>Responsibilities</h3>
                <ul>
                    <li>Executing and optimizing global mobile marketing campaigns across all channels such as Facebook, Google, DSPs and Ad Networks</li>
                    <li>Communication of UA strategies between departments and shareholders</li>
                    <li>Conducting extensive creative analysis and collaborating with the design team on new concepts</li>
                    <li>Supporting innovation and growth through multi-platorm A/B testing, continuous expenmentation with a balanced approach between art and science</li>
                    <li>Research, test and evaluate new ad channels, targeting and bidding methods, and automated solutions</li>
                    <li>Designing projects to develop and improve according to user needs and to further grow the brand</li>
                </ul>
                <h3>Requirements</h3>
                <ul>
                    <li>3+ years of experience in mobile marketing, ideally in the gaming industry</li>
                    <li>Proficient in Excel</li>
                    <li>Strong analytical skills to make data-driven decisions</li>
                    <li>Creative and result-oriented mindset</li>
                    <li>Excellent written and verbal communication skills, including strong writing and editing skills</li>
                    <li>Passion and interest in games</li>
                    <li>Appreciating and understanding teamwork</li>
                </ul>
                <img className='d-block mt-5' src="/images/Logo.png" alt="" width="106" />
                <a href='#!' onClick={(e) => {window.location.href ='mailto:hr@thesplashlabs.com';}} className='buttonPrimary mt-5'>Apply Now</a>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default MarketingManager