import React from 'react'

function TextCard() {
  return (
    <div className='textCardScope' id='About'>
        <div className='container'>
            <div className='textCardBlock'>
                <h2>Splash,</h2>
                <p><strong>Paris</strong> based mobile game and application company. <br/>
                    With our teams in the Zurich and Dubai, it is an application that will make people's lives easier and have more fun. <strong>We produce games that will All team</strong> members at Splash have the endless opportunity to contribute to the final product and shape 
                    the future of the company.
                </p>
            </div>
        </div>
    </div>
  )
}

export default TextCard