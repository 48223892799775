import React from "react";
import "../src/styles/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import Careers from "./components/pages/Careers";
import SeniorGameDeveloper from "./components/pages/SeniorGameDeveloper";
import MarketingManager from "./components/pages/MarketingManager";
import SeniorBackendEngineer from "./components/pages/SeniorBackendEngineer";
import ProductSpecialist from "./components/pages/ProductSpecialist";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsAndConditions from "./components/pages/TermsAndConditions";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route
            path="/careers/SeniorGameDeveloper"
            element={<SeniorGameDeveloper />}
          />
          <Route
            path="/careers/MarketingManager"
            element={<MarketingManager />}
          />
          <Route
            path="/careers/SeniorBackendEngineer"
            element={<SeniorBackendEngineer />}
          />
          <Route
            path="/careers/ProductSpecialist"
            element={<ProductSpecialist />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions/defense-quest"
            element={<TermsAndConditions.DefenseQuest />}
          />
          <Route
            path="/terms-and-conditions/dog-escape"
            element={<TermsAndConditions.DogEscape />}
          />
          <Route
            path="/terms-and-conditions/grand-clash"
            element={<TermsAndConditions.GrandClash />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
