import React from 'react'

function ProductSpecialist() {

  return (
    <div className='positionDetailScope'>
    <div className='container'>
        <div className='positionDetailBlock'>
            <div className='positionDetailBackBlock'>
                <a href="/careers"> 
                <span className='backIcon'>
                    <img src='/images/ArrowLeft.png' width={7} alt=''></img>
                </span> 
                <span>Back</span> </a>
            </div>
            <div className='positionDetailContentBlock'>
                <h1>Product Specialist</h1>
                <h3>Jod Description</h3>
                <p>We are looking for a passionate Product Specialist to join our team. As a Product Specialist, you will contribute to the development of the product roadmap, ensuring the success and growth of the game. At the studio, you'll work closely with engineers and artists to create games that will be played by millions of people around the world. </p>
                <p>At The Splash Labs, all team members have a real chance to contribute to the final product and make an impact on the company. If you want to be a core member of a rapidly growing company, you may be the one we are looking for!</p>
                <h3>Responsibilities</h3>
                <ul>
                    <li>Improving the gaming experience based on metrics and user feedback</li>
                    <li>Designing game mechanics and features</li>
                    <li>Thinking creatively to design unique, fun and engaging features for our players</li>
                    <li>Analyzing massive amounts of player data to make design decisions</li>
                    <li>Configure, run, and monitor A/B tests and gain insights using statistical analysis</li>
                    <li>Keep up-to-date on the mobile market, play and analyze mobile games, and share new insights with the team</li>
                </ul>
                <h3>Requirements</h3>
                <ul>
                    <li>Passion for games and game development</li>
                    <li>Strong analytical skills to make data-driven decisions</li>
                    <li>Excellent communication skills</li>
                    <li>Understanding and appreciating teamwork.</li>
                </ul>
                <img className='d-block mt-5' src="/images/Logo.png" alt="" width="106" />
                <a href='#!' onClick={(e) => {window.location.href ='mailto:hr@thesplashlabs.com';}} className='buttonPrimary mt-5'>Apply Now</a>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default ProductSpecialist