import React from 'react';
import '../../../src/styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import OpenPositions from '../OpenPositions';



function Positions(){
    return(
        <>
            <OpenPositions />
        </>
    )
}

export default Positions;