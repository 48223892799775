import React from 'react'

function SeniorGameDeveloper() {

  return (
    <div className='positionDetailScope'>
        <div className='container'>
            <div className='positionDetailBlock'>
                <div className='positionDetailBackBlock'>
                    <a href="/careers"> 
                    <span className='backIcon'>
                        <img src='/images/ArrowLeft.png' width={7} alt=''></img>
                    </span> 
                    <span>Back</span> </a>
                </div>
                <div className='positionDetailContentBlock'>
                    <h1>Senior Game Developer</h1>
                    <h3>Jod Description</h3>
                    <p>At The Splash Labs, we are looking for innovative Game Developers who will improve themselves every day and spread their positive energy around.</p>
                    <p>We accept both remote and onsite applications</p>
                    <h3>What are we waiting for ?</h3>
                    <ul>
                        <li>Being enthuslastic about Hyper Casual and Casual game development,</li>
                        <li>To contribute fully to the creative process</li>
                        <li>Entrepreneurial mindset, high energy, motivation and a strong sense of ownership,</li>
                        <li>Ability to work as a greasean oldyes.</li>
                        <li>Ability to work quickly and efficiently,</li>
                        <li>At least 3 years of professional work experience with Unity 3D,</li>
                        <li>Proticient in Of language</li>
                        <li>Experience with source code management tools such as Git/SVN</li>
                        <li>(Optional) Shader programming experience,</li>
                        <li>Excellent command of English.</li>
                    </ul>
                    <h3>What should you expect ?</h3>
                    <ul>
                        <li>As part of our team, you will work with the Art and Game Design teams</li>
                        <li>You will be responsible for prototyping, maintaining and developing new and unique games that will reach millions of users.</li>
                        <li>You will be involved in the creation and development of new popular games</li>
                        <li>You will realize error-tree and sustainable coding.</li>
                        <li>You will work hard with the team, learn a lot and have a great time</li>
                    </ul>
                    <h3>What will you learn ?</h3>
                    <ul>
                        <li>You will witness the product lifecycle, from coming up with an idea to making it to the top.</li>
                        <li>You will develop reverse engineering skills</li>
                        <li>You will gain experience in optimizing game pertormance</li>
                        <li>You will leam to share with the team, act as a team and transter knowledge</li>
                    </ul>
                    <h3>What do we provide ?</h3>
                    <ul>
                        <li>it you want to be part of our team or at least chat with us, send us your resume</li>
                    </ul>
                    <img className='d-block mt-5' src="/images/Logo.png" alt="" width="106" />
                    <a href='#!' onClick={(e) => {window.location.href ='mailto:hr@thesplashlabs.com';}} className='buttonPrimary mt-5'>Apply Now</a>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default SeniorGameDeveloper